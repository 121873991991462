<template>
    <div class="image-cell">
        <FilePickCell :data="data" @change="pickedFile($event)" />

        <div class="row">
            <label :for="'alt'+_.uid">{{ $t('cms.alt_text') }}: </label>
            <input type="text" :id="'alt'+_.uid" v-model="alt" @change="$emit('change',{url: data.url,alt:alt});">
        </div>
        
    </div>
</template>

<script>
import {  ref } from 'vue';
import FilePickCell from "@admin/components/cms/file/FilePickCell";

export default {
    name: "ImageCellAdmin",
    components: {
        FilePickCell
    },
    props: {
        data: Object
    },
    emits: [
        "change"
    ],
    setup(props,context){
        const pickFile = ref(false);
        const filePicked = ref(null);
        const startPicking = () => {
            pickFile.value = true;
            filePicked.value = null;
        };
        const stopPicking = () => {
            pickFile.value = false;
        };
        const pickedFile = (data) => {
            context.emit('change',{url: data.url,alt:props.data.alt});
            stopPicking();
        };

        const alt = ref(props.data.alt);

        return {
            filePicked,
            pickedFile,
            stopPicking,
            startPicking,
            pickFile,
            alt,
        };

    }
}
</script>

<style scoped>
.image-cell>.img{
    max-width: 100%;
    /* max-height: 30rem;
    min-height: 20rem; */
    height: 20rem;
    margin: auto;
    display: block;
    cursor: pointer;
    border: 5px solid transparent;
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    flex-direction: column;
    align-items: center;
}
.image-cell>.img:hover{
    border: 5px solid #177542;
}
.image-cell>.img>img{
    max-height: 100%;
    max-width: 100%;
}

.row{
    font-size: 0.9rem;
    margin: 1rem 0 0.5rem 0;
    text-align: center;
}
.row>label{
    margin-right: 0.5rem;
}

</style>